.slick-slide > div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-next:before {
    color: #343a40;
}

.slick-prev:before {
    color: #343a40;
}